<template>
<div>
    <div v-if="isLoading">
        <CanPayLoader/>
    </div>
  <div class="container min-h py-3" v-else>


    <div >
        
        <RwDatePicker @change="onSelectDate" class="mb-3" v-model="filter"/>

        <div v-if="rewardPointHistories.length > 0" class="historyScrollArea" id="historyScrollArea">
            <h5 class="text-left text-white mb-3">History</h5>
            <a v-for="history in rewardPointHistories" :key="history.id" class="rp-detail-card-link" data-toggle="collapse" href="#rpDetailCollapse1" role="button" aria-expanded="false" aria-controls="rpDetailCollapse1">
            <div class="rp-detail-card mb-3 pb-1">
                <div class="rp-detail-card-header">
                    <div v-if="history.sponsor_link_id" class="tran-title mb-2">+${{ pointNumberFormatter(amountNumberFormatter(history.reward_amount)) }}</div>
                    <div v-if="history.sponsor_link_id" class="tran-store-name mb-0">CannaPlan Funds to Points</div>
                    <hr v-if="history.sponsor_link_id" class="my-2">
                    <div class="row justify-content-between m-0">
                        <div class="rp-title">
                            <svg class="mr-2" width="30" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="history.segment_name!= 'Loser'">
                            <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="black"/>
                            <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                            <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="black"/>
                            <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                            </svg>
                            <span v-if="history.entry_type == 'Cr'">
                                <span v-if="history.segment_name">
                                    <span v-if="history.segment_name == 'Amount'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                    <span v-if="history.segment_name == 'Jackpot'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                    <span v-if="history.segment_name == 'Free Spin'">{{pointNumberFormatter(history.reward_point)}} spin</span>
                                    <span v-if="history.segment_name == 'Reward Points'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                    <span v-if="history.segment_name == 'Multiplier'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                    <span v-if="history.segment_name == 'Loser'">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                </span>
                                <span style="font-weight: 700; color: #007ee5;" v-else-if="history.sponsor_link_id">{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                <span v-else>{{pointNumberFormatter(history.reward_point)}} {{ parseInt(history.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                <span v-if="history.status.toLowerCase() == 'pending' && history.segment_name!= 'Loser'" class="font-weight-bold ml-2"><small style="font-weight: 700; color: #007ee5;">(Pending)</small></span>
                            </span>
                            <span v-if="history.entry_type == 'Dr'">${{pointNumberFormatter(amountNumberFormatter(history.reward_amount))}}</span>
                            <span v-if="history.segment_name == 'Loser'" class="rp-text" style="text-transform:none;">Sorry! This spin didn’t win. <br/>
                            But it did gain you entry into the Daily 2nd Chance Drawing. Good luck!</span>
                        </div>
                        <span v-if="history.segment_name">
                            <div v-if="history.entry_type == 'Cr'" class="rp-text" style="text-transform: capitalize;"><span class="text-dark">Win:</span> {{history.segment_name}}</div>
                            <!-- <div v-if="history.entry_type == 'Cr' && history.segment_name == 'Loser'" class="rp-text" style="text-transform: capitalize;">{{history.reason}}</div> -->
                            <div v-if="history.entry_type == 'Dr'" class="rp-text" style="text-transform: capitalize; color: #007EE5!important;">Paid with Points</div>
                        </span>
                        <div v-else-if="history.sponsor_link_id" class="rp-text" style="font-weight: 700; color: #007ee5;">Canpay Points</div>
                        <div v-else class="rp-text" style="text-transform: capitalize;">{{history.reason}}</div>
                    </div>
                    <div v-if="history.entry_type == 'Cr' || history.segment_name== 'Loser'" class="font-weight-bold mt-2">{{history.reward_wheel_name}}</div>
                    <div v-if="history.entry_type == 'Dr'" class="font-weight-bold mt-2">{{history.retailer}}</div>
                    <hr class="my-2 mb-1">
                    <ul class="rp-timing">
                        <li class="d-flex align-items-center">
                        <svg
                        class="mr-2"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 90 90"
                        style="
                            enable-background: new 0 0 90 90;
                            margin-left: -8px;
                            margin-right: -10px;
                            float: left;
                        "
                        xml:space="preserve"
                        width="30"
                        fill="#7f7f7f"
                        >
                        <path
                            d="M63,25h-2v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2H33v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2h-2c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h36
                        c3.3,0,6-2.7,6-6V31C69,27.7,66.3,25,63,25z M65,63c0,1.1-0.9,2-2,2H27c-1.1,0-2-0.9-2-2V31c0-1.1,0.9-2,2-2h2v2c0,1.1,0.9,2,2,2
                    c1.1,0,2-0.9,2-2v-2h24v2c0,1.1,0.9,2,2,2s2-0.9,2-2v-2h2c1.1,0,2,0.9,2,2V63z"
                        />
                        <path
                            d="M59,39H31c-1.1,0-2,0.9-2,2s0.9,2,2,2h28c1.1,0,2-0.9,2-2S60.1,39,59,39z"
                        />
                        </svg> {{formatedTime(history.rewards_time)}} - {{ history.timezone_name }}</li>
                    </ul>
                </div>
            </div>
            </a>
            <div v-show="loading" class="spinner-border"></div>
            <div v-if="!noMoredata" class="text-center"><button class="load-more-button-style" @click="loadMoreData()">Load more</button></div>
            <p v-if="noMoredata" >No more data found</p>
        </div>
        <div v-else class="text-white mt-5">
            <small>No history found!</small>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import moment from "moment";
import rewardwheelapi from "../../api/rewardwheel.js";
import RwDatePicker from "./RwDatePicker.vue";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
    name: "Transactions",
    data() {
        return {
            isLoading: true,
            rewardPointHistories: [],
            filter: {
                tenure: '',
                from_date: '',
                to_date: ''
            },
            formDateValidate: null,
            toDateValidate: null,
            pagination:{
                total: null,
                page: 1,
                current_page: 1,
                rowsPerPage: process.env.VUE_APP_REWARD_HISTORY_PER_PAGE,
                to: process.env.VUE_APP_REWARD_HISTORY_PER_PAGE
            },
            loading: false,
            noMoredata: false
        };
    },
    watch: {
        
    },
    components: {
        Loading,
        RwDatePicker,
        CanPayLoader
    },
    mounted() {
        var element = document.getElementsByClassName("content-wrap");
        if (element[0]) {
            element[0].style.setProperty("background", "linear-gradient(0deg, #148f3f, #00ae44)");
        }
        this.$root.$emit("loginapp", [""]);
        this.$root.$emit("changeWhiteBackground", [
            false,
            false,
            "RewardPointHeader"
        ]);
        
        this.filter.tenure = 'last-week'
        this.filter.from_date = moment().subtract(6, 'd').format('YYYY-MM-DD')
        this.filter.to_date = moment().format('YYYY-MM-DD')
        this.getRwardHistory({
            from_date: this.filter.from_date, 
            to_date: this.filter.to_date,
            page: this.pagination.page,
            rowsPerPage: this.pagination.rowsPerPage
        });

        var app = document.getElementById('nav-drawer');

        app.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    methods: {
        formatedTime(data){
            return moment(data).format('DD MMM Y | hh:mm A');
        }, 
        loadMoreData(){
            var self = this;
            if(!self.noMoredata){
                rewardwheelapi
                .rewardPointHistory({
                    from_date: self.filter.from_date, 
                    to_date: self.filter.to_date,
                    page: self.pagination.page+1,
                    rowsPerPage: self.pagination.rowsPerPage
                })
                .then((response) => {
                    self.pushToHistoryArray(response.data)
                    
                    if(response.data.total<=response.data.per_page || response.data.to == response.data.total || response.data.to==null){
                        self.noMoredata = true
                    }

                })
                .catch(function (error) {
                    
                })
            }

        },
        getRwardHistory(params){
            var self = this;
            rewardwheelapi
            .rewardPointHistory(params)
            .then((response) => {

                // Set Pagination 
                self.pagination.rowsPerPage = response.data.per_page
                self.pagination.current_page = response.data.current_page
                self.pagination.total = response.data.total
                self.pagination.to = response.data.to

                self.rewardPointHistories = response.data.data;
                if(response.data.total<=response.data.per_page || response.data.to == response.data.total || response.data.to==null){
                    self.noMoredata = true
                }else{
                    self.noMoredata = false;
                }
                self.isLoading = false
            })
            .catch(function (error) {
                self.isLoading = false
            })
        },
        routePush(url){
            this.$router.push("/"+url).catch((err) => {});
        },
        pushToHistoryArray(payload){

            var self = this
            
            self.rewardPointHistories.push(...payload.data) 

            self.pagination.rowsPerPage = payload.per_page
            self.pagination.current_page = payload.current_page
            self.pagination.page += 1
            self.pagination.total = payload.total
            self.pagination.to = payload.to
            self.loading = false
        },
        onSelectDate(){
            let self = this;
            if(this.rewardPointHistories.length > 0){
                document.getElementById('historyScrollArea').scroll({top:0});
            }

            this.pagination.rowsPerPage = process.env.VUE_APP_REWARD_HISTORY_PER_PAGE
            this.pagination.current_page = 1
            this.pagination.page = 1
            this.pagination.total = null
            this.pagination.to = process.env.VUE_APP_REWARD_HISTORY_PER_PAGE

            this.getRwardHistory({
                from_date: this.filter.from_date, 
                to_date: this.filter.to_date,
                page: this.pagination.page,
                rowsPerPage: this.pagination.rowsPerPage
            });
        },
        amountNumberFormatter(amount) {
            return parseFloat(amount).toFixed(2);
        }
    }
};
</script>


<style scoped>
.min-h{
    min-height: calc(100vh - 115px)!important;
}
.load-more-button-style{
    width:50%;
    border:none;
    border-radius: 6px;
    padding:15px;
    background-color: #000;
    font-weight:600;
    color:#fff;
}
.rp-available-card{
    background: linear-gradient(45deg, #242424, black);
    padding: 20px;
    border-radius: 6px;
    position: relative;
}
.rp-available-title{
    text-align: left;
    color: #dddddd;
}
.rp-available-value{
    text-align: left;
    color: #199448;
    font-size: 40px;
}
.rp-icon-avatar{
    border-radius: 100%;
    background: #1e1e1e;
    position: absolute;
    top: 15px;
    right: 15px;
}

.rp-pending-card{
    padding: 20px;
    border-radius: 6px;
    position: relative;
    background: linear-gradient(45deg, #0e8740, #006b2c);

}
.rp-pending-text{
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}
.rp-pending-text .text{
    color: #fff;
    margin-left: 10px;
}
.rp-pending-text .value{
    color: #fff;
    font-size: 30px;
}
.rp-imark-avatar{
    position: absolute;
    top: 4px;
    right: 7px;
}
.rp-invite-card{
    background: #fff;
    border-radius: 6px;
}
.rp-invite-card-header{
    background: #e7e8e7;
    padding: 20px;
    font-size: 23px;
    color: #00943f;
    text-align: left;
    font-weight: bold;
    border-radius: 6px 6px 0 0;
}
.rp-invite-card-body{
    background: #ffffff;
    padding: 20px;
    border-radius: 0 0 6px 6px;
    text-align: left;
}
.rp-invite-card-body .content{
    background: #ffffff;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.rp-invite-card-body .link{
    background: #ffffff;
    color: #00943f;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
    font-weight: bold;
}

.rp-detail-card-link, .rp-detail-card-link:hover{
    text-decoration: none;
    color: unset;
}
.rp-detail-card{
    background: white;
    border-radius: 7px;
    text-align: left;
}

.rp-detail-card{
    padding: 10px 20px;
}
.rp-title{
    font-weight: 800;
    font-size: 19px;
}
.rp-text{
    font-weight: 500;
    font-size: 15px;
    color: #00943f;
    font-weight: 700;
    text-transform: capitalize;
}
.rp-timing{
    list-style: none;
    margin: 0;
    padding: 0;
    color: #919090;
    font-size: 13px;
    font-weight: 600;
}

.rp-history-link{
    color: #fff;
    text-decoration: underline;
}

.btn-submit-filter{
    background-color: #000;
    color: #fff;
    border: 0;
    padding: 14px 34px;
    border-radius: 8px;
}
.vdate-picker{
    border-radius: 8px;
}

.rw-loading{ 
    position: absolute;
    bottom: 5%;
    padding: 10px 10px;
    color: #fff;
    text-align: center;
    background: #000;
    border-radius: 4px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
}
.fade-enter-active,
.fade-leave-active{
    transition: opacity .25s;
}
.fade-enter,
.fade-leave-to{
    opacity: 0;
}

.tran-store-name{
    font-weight: 600;
    font-size: 16px;
}

.tran-title {
    font-weight: 600;
    font-size: 25px;
}
</style>